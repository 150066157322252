import React, { useEffect, useState } from "react";

import HubspotForm from 'react-hubspot-form';

import "../styles/home.css";
import "../styles/homeResponsive.css";

import nearYou from "../images/logo_white.webp";
import market1 from "../images/market1.webp";
import store from "../images/banner_image.webp";
import franchise from "../images/franchise.webp";
import benefit from "../images/benefit_example.webp";
import google from "../images/google.webp";
import hyperlocal from "../images/Hyperlocal1.webp";
import adwprds from "../images/adwords.webp";
import onlineStore from "../images/online-store.webp";
import onlineStore1 from "../images/microweb.webp";
import reputation from "../images/reputation.webp";
import group2 from "../images/orm_sample.webp";
import companyOwned from "../images/company_owned.webp";
import dealership from "../images/dealership.webp";
import frame from "../images/store_health.webp";
import selection from "../images/selection.webp";
import group3 from "../images/review_wa.webp";
import remarketing from "../images/remarketing.webp";
import iphone from "../images/journey_wa.webp";
import storeVisitor from "../images/storeVisitor.webp";

function Home() {
  return (
    <>
      <section className="section1">
        <div className="subSection">
        <div className="navbar">
          <div className="nearYou">
            <img src={nearYou} alt="Description" />
          </div>
          <div className="navbarMenu">
            <a href='#services' className="menuName">Services</a>
            <a href='#product' className="menuName">Product</a>
            <a href='#pricing' className="menuName">Pricing</a>
            <a className="menuName"> +91 - 935383 7677</a>
            {/* <a href='https://api.whatsapp.com/send/?phone=919353837677&text&type=phone_number&app_absent=0' className="menuName"> +91 - 935383 7677</a> */}
            <a href='#contact-us' className="contactUs headerButton">Contact Us</a>
          </div>
        </div>
        <div className="heroSection">
          <div className="businesses">
            <div className="multiBussiness">
                <span className="dot"></span>
                <span className="multi">FOR MULTI LOCATION BUSINESSES</span>
            </div>
            <h1>
              Drive Footfall <br />
              Maximise Sales
            </h1>
            <h2>
              Improve Visibility, Conversion, and Loyalty for offline <br />
              stores through our AI Powered Localised Marketing <br /> Platform
            </h2>
            
            <div className='section1Button mobileBookADemo'>
            <a href='#contact-us' className="contactUs">Book a Demo</a>
            </div>
                
              
            
          <div className="designedFor">
            <h3 className="designedForText">Designed for</h3>

            <div className="designed">
              <div className='storeType'>
              <img src={franchise} alt="Description" />
              <span className='storeTypeText'>Franchise</span>
              </div>
              <div className='storeType'>
              <img src={companyOwned} alt="Description" />
              <span className='storeTypeText'>Company Owned</span>
              </div>
              <div className='storeType'>
              <img src={dealership} alt="Description" />
              <span className='storeTypeText'>Dealerships</span>
              </div>
            </div>
            <div className="storeVisitor">
            <img src={storeVisitor} alt="Description" />
            </div>
          </div>
          </div>
          <div className="storeMobile">
            <img src={store} alt="Description" />
          </div>
        </div>
        </div>
      </section>
      <section id='services' className="section2">
        <div className="customers">
          <h1>
            Engage local customers, <span>digitally</span>
          </h1>
          <p>
            AI powered marketing tools to create an impact across entire
            customer funnel
          </p>
        </div>
        <div className="visiablitySection">
          <div className="visibility">
          <div className="visibilityMobile">
            <img src={benefit} alt="Description" />
          </div>
            <div className="visiblityColumn">
            <h1>Improve Store Visibility</h1>
            <p>
              Build online presence of stores through Google Business Profile, Facebook and Store Locators 
            </p>
            </div>

            <div className="visiblityColumn">
            <h1>Generate High Quality Leads</h1>
            <p>
              Craft localized marketing campaigns on Google, WhatsApp, and Facebook
            </p>
            </div>

            <div className="visiblityColumn">
            <h1>Maximise Conversion and CLTV</h1>
            <p>
              Re-engage with potential customers through targeted WhatsApp & SMS campaigns
            </p>
            </div>

            <div className="visiblityColumn">
            <h1>Improve Brand Equity & Loyalty</h1>
            <p>
              Collect customer reviews and referrals via targetted WhatsApp & SMS outreach
            </p>
            </div>

            <div className="visiblityColumn">
            <h1>Monitor Store Performance</h1>
            <p>
              Access performance analysis reports for all stores from CxO level to in-depth analysis
            </p>
            </div>
            <div className="contactUsMobileM">
            <a href='#contact-us' className="contactUs headerButton contactUsMobile">Contact Us</a>
            </div>
          </div>
          <div className="visibility visibilityNone">
            <img src={benefit} alt="Description" />
          </div>
        </div>
      </section>
      <section id='product' className="section3">
        <div className="subSection3">
          <div className="allInOne">
            <h1>All-In-One Marketing Solution</h1>
            <p>
              AI powered marketing tools to create an impact across entire
              customer funnel
            </p>
          </div>
          <div className="mainMarketingSolution">
            <div className="row">
            <div className="management">
              <div className="googledisc">
                <img src={google} alt="Description" />
                <h2>GMB Management & SEO</h2>
                <p>
                  One place to manage Google Business Profiles for all
                  stores
                </p>
              </div>
              <div className="gSearch">
                <img src={hyperlocal} alt="Description" />
              </div>
            </div>
            <div className="management">
              <div className="googledisc">
                <img src={adwprds} alt="Description" />
                <h2>Hyperlocal Google Ads</h2>
                <p>
                  Localized store visit Google Ad campaigns for each
                  store to drive local store visits
                </p>
              </div>
              <div className="gSearch">
              <img src={hyperlocal} alt="Description" />
              </div>
            </div>
            </div>

            <div className="row rowMobile">
            <div className="row2container">
              <div>
                <img src={onlineStore} alt="Description" />
                <h2>Micro Website for Stores</h2>
                <p>Personalised website and landing pages for each store</p>
              </div>
              <div className="row2containerimg">
                <img src={onlineStore1} alt="Description" className="frame" />
              </div>
            </div>
            <div className="row2container">
              <div>
                <img src={reputation} alt="Description" />
                <h2>Reputation Management</h2>
                <p>
                  Automated contextual responses for all reviews to
                  ensure a 100% reply rate
                </p>
              </div>
              <div className="row2containerimg">
                <img className="frame" src={group2} alt="Description" />
              </div>
            </div>
            <div className="row2container">
              <div>
                <img src={market1} alt="Description" />
                <h2>Store Health Check</h2>
                <p>
                  In-depth location reports by integrating data from
                  various channels like GMB, Google Ads, and CRM
                </p>
              </div>
              <div className="row2containerimg">
                <img src={frame} alt="Description" className="frame" />
              </div>
            </div>
            <div className="row2container  row2containerMobile">
              <div>
                <img src={selection} alt="Description" />
                <h2>Review and Referralk</h2>
                <p>
                  In-depth location reports by integrating data from
                  various channels like GMB, Google Ads, and CRM
                </p>
              </div>
            </div>
            </div>

            <div className="row">
            <div className="row2container rowContainerMobile">
              <div>
                <img src={selection} alt="Description" />
                <h2>Review and Referral</h2>
                <p>
                  In-depth location reports by integrating data from
                  various channels like GMB, Google Ads, and CRM
                </p>
              </div>
              <div className="row2containerimg">
                <img src={group3} alt="Description"  className="frame"/>
              </div>
            </div>

            <div className="Remarketing">
              <div className="remarketingtxt">
                <img src={remarketing} alt="Description" />
                <h2>Customer journey based re-marketing</h2>
                <p>
                  Personalized remarketing campaigns based on customer
                  journey through WhatsApp and SMS
                </p>
              </div>
              <div className="remarketingimg">
                <img src={iphone} alt="Description" className="frame" />
                {/* <img className="sponsered" src={sponsered} alt="Description" /> */}
              </div>
            </div>
            </div>



          </div>
        </div>
      </section>
      <section id='pricing' className="section4">
        <h1 className="Service">Service Models</h1>
        <p className="ServicePara">
          All business have different needs and we are here to serve them all
        </p>
        <div className="serviceSection">
          <div className="serviceCard">
            <div>
            <h1 className="serviceHeading">
              Platform Subscription
            </h1>
            <p className="serviceSubheading">Managed by in-house team</p>
            <div className="borderBottom"></div>
            <p className="serviceDesc">
              Give power to your marketing team to manage all store
              marketing activity in a hassle free manner
            </p>
            </div>
            <a href='#contact-us' className="bookADemo">Book a Demo</a>
          </div>
          <div className="serviceCard platform">
            <div>
            <h1 className="serviceHeading platformHeading">
              Platform & Service
            </h1>
            <p className="serviceSubheading palteformPara1">Managed by Near You media</p>
            <div className="borderBottom2"></div>
            <p className="serviceDesc platformDesc">
              Get end to end service from experienced marketing professionals of Near You Media
            </p>
            </div>
            <a href='#contact-us' className="contactUs contactUsPlateform">Contact Us</a>
          </div>
        </div>
      </section>
      <section id='contact-us'>
      <div className="contactushubspot">
        <div className="contactusheading">
        <h1>
            One-stop Marketing Solution for Multi-location Brands
          </h1>
          <p>
            Near You revolutionizes marketing by amplifying visibility and
            conversion for all stores, spanning discovery to purchase to
            loyalty.
          </p>
          </div>
          </div>
        <div className="hubspotForm">
        <HubspotForm
              portalId='46222668'
              formId='479fe741-b4b3-4e57-a81a-33b856eb06b7'
              onSubmit={() => console.log('Thanks for submitting the form!')}
              onReady={(form) => console.log('Form is ready!')}
              loading={<div>Loading...</div>}
        />
          </div>
        
      </section>
      <section className="section6">
        <div className="footerSection">
          <div className="nearYouSection">
            <div className="nearYouMobile">
            <img src={nearYou} alt="Description" />
            </div>
            <p className="omnichannel">
              Near You is an omni-channel marketing platform <br /> enhancing
              the entire customer journey from <br /> discovery to purchase to
              loyalty.
            </p>
            <div className="getInTouch">
            <div className="conatactNo">
              <p className="omnichannel1">Contact Us</p>
              <p className="omnichannel4">+91 - 93 53 83 7677</p>
            </div>
            {/* <div className="mainGetInTouch">
              <p>Get in Touch</p>
            </div> */}
            <a href='#contact-us' className="contactUs contactUsPlateform">Contact Us</a>

            </div>
          </div>
          <div className="Sitemap">
            <h3>Sitemap</h3>
            <a href="#services" ><p>Services</p> </a>
            <a href="#product"> <p>Product</p> </a> 
            <a href="#pricing"> <p>Pricing</p> </a> 
            <a href="#contact-us"> <p>Book a Demo</p> </a>  
          </div>
          <div className="address">
            <h3>India</h3>
            <p>
              Survey Number 3, <br />
              Aishwarya Complex <br /> GoSpaze, Sarjapur Main Road <br />
              Bengaluru <br /> Karnataka 560103
            </p>
          </div>
          <div className="termsAndCondition termsAndConditionMobile">
          <h2>Terms & Conditions</h2>
          <ul>
            <li>
            Privacy Policy
            </li>
          </ul>
        </div>
        </div>

      </section>
    </>
  );
}
export default Home;




// const Home = () => {
//     const navigate = useNavigate();
//     const[token, setToken, removeToken] = useCookies(['access-token', 'refresh-token']);

//     const responseMessage = async (response) => {
//         try {
//             const res = await fetch(`http://127.0.0.1:8000/v1/auth/google/`,
//                 {
//                     method: "POST",
//                     body: JSON.stringify({
//                         access_token: response.credential
//                     }),
//                     headers: {
//                         "Content-Type": "application/json",
//                     },
//                 });
//             const data = await res.json();
//             if (data.access) {
//                 setToken('access-token',data.access);
//                 setToken('refresh-token',data.refresh);

//                 // Router.push('/')
//                 navigate("/overview")

//             }
//             console.log(data);
//         } catch (err) {
//             console.log(err);
//         }

        
//         // navigate("/overview")
       
//     };

//     const errorMessage = (error) => {
//         console.log(error);
//     };

// return (<div className="container">

//     <div className="box1"> 
//     <div className="logo">
//     <img
//           src={logo}
//           className="logo"
//           alt="near-you"
//         />
//     <div className="mission-text">Best AI Driven Multi Location Marketing Platform </div>

//     </div>
//        </div>
//     <div className="box2"> 
//     <div className="center"> 
//     <div className="login-txt">LOGIN</div>
//     <GoogleLogin onSuccess={responseMessage} onError={errorMessage} theme="filled_blue" text="Login with Google" ></GoogleLogin>
//     </div>
    
//      </div>




// </div>)
// }

// export default Home;